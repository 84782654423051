import React, { createContext, useContext, useEffect, useState } from 'react';
import { isPlatform } from "@ionic/react";
import { Network } from '@capacitor/network';

const iosOrAndroid = isPlatform("hybrid");

const NetworkContext = createContext();

export function NetworkProvider({ children }) {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    let networkListener = undefined;

    const checkInitialStatus = async () => {
      try {
        if (iosOrAndroid) {
          const status = await Network.getStatus();
          setIsOnline(status.connected);
          console.log('Initial Network status:', status.connected);
        }
      } catch (error) {
        console.error('Error checking initial network status:', error);
      }
    };

    checkInitialStatus();

    if (iosOrAndroid) {
      networkListener = Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed:', status);
        setIsOnline(status.connected);
      });
    } else {
      window.addEventListener("online", () => setIsOnline(true));
      window.addEventListener("offline", () => setIsOnline(false));

      return () => {
        window.removeEventListener("online", () => setIsOnline(true));
        window.removeEventListener("offline", () => setIsOnline(false));
      };
    }

    return () => {
      if (networkListener) networkListener.remove();
    };
  }, []);

  return (
    <NetworkContext.Provider value={{ isOnline }}>
      {children}
    </NetworkContext.Provider>
  );
}

export function useNetwork() {
  return useContext(NetworkContext);
}