const database = {
    users: [
        { id: 1, username: "Alice" },
        { id: 2, username: "Louis" },
        { id: 3, username: "Élise" },
        { id: 4, username: "Antoine" },
        { id: 5, username: "Claire" },
    ],
    chats: [
        { id: 1, client_id: "L'Homme Gentil" },
        { id: 2, client_id: "Maison bleue" },
        { id: 3, client_id: "Les Escargots" },
        { id: 4, client_id: "Le Chien Gris" },
        { id: 5, client_id: "Le Train Vert" },
    ],
    messages: [
        {
            id: 1,
            chat_id: 1,
            user_id: "1",
            content:
                "Bonjour tout le monde, avez-vous des retours sur la nouvelle gamme de bières ?",
            mentions: [2],
            created_at: "2024-12-12T10:00:00Z",
        },
        {
            id: 2,
            chat_id: 2,
            user_id: 2,
            content:
                "Le client Boulangerie du coin a demandé plus de stocks de boissons gazeuses.",
            mentions: [1, 3],
            created_at: "2024-12-12T11:00:00Z",
        },
        {
            id: 3,
            chat_id: 3,
            user_id: 3,
            content:
                "Le pub a bien aimé les dernières livraisons de jus d'orange, mais il faut plus de variétés.",
            mentions: [4],
            created_at: "2024-12-12T12:00:00Z",
        },
        {
            id: 4,
            chat_id: 4,
            user_id: 4,
            content:
                "La Boulangerie du coin souhaite organiser une dégustation de nos nouvelles boissons.",
            mentions: [5],
            created_at: "2024-12-12T13:00:00Z",
        },
        {
            id: 5,
            chat_id: 5,
            user_id: 5,
            content:
                "Supermarché Delice veut tester une offre spéciale de boissons énergétiques.",
            mentions: [2],
            created_at: "2024-12-12T14:00:00Z",
        },
        // Additional messages for chat_id 1
        {
            id: 6,
            chat_id: 1,
            user_id: 2,
            content:
                "J'ai vu les retours des clients sur les bières. Ils semblent apprécier, mais certains trouvent le goût un peu trop fort.",
            mentions: [1],
            created_at: "2024-12-12T10:30:00Z",
        },
        {
            id: 7,
            chat_id: 1,
            user_id: 3,
            content:
                "Peut-être que nous devrions proposer une version moins forte pour toucher un plus large public.",
            mentions: [1],
            created_at: "2024-12-12T10:45:00Z",
        },
        {
            id: 8,
            chat_id: 1,
            user_id: 1,
            content:
                "Bonne idée, nous allons tester ça avec une nouvelle production la semaine prochaine.",
            mentions: [2, 3],
            created_at: "2024-12-12T11:00:00Z",
        },

        // Additional messages for chat_id 2
        {
            id: 9,
            chat_id: 2,
            user_id: 1,
            content:
                "Je vais m'assurer que nous envoyons les stocks de boissons gazeuses avant la fin de la semaine.",
            mentions: [2],
            created_at: "2024-12-12T11:30:00Z",
        },
        {
            id: 10,
            chat_id: 2,
            user_id: 3,
            content:
                "Parfait, mais il serait peut-être judicieux de discuter de la qualité des produits avec le client.",
            mentions: [2, 1],
            created_at: "2024-12-12T11:45:00Z",
        },
        {
            id: 11,
            chat_id: 2,
            user_id: 2,
            content:
                "Je vais organiser une réunion avec eux pour régler les détails.",
            mentions: [1],
            created_at: "2024-12-12T12:00:00Z",
        },

        // Additional messages for chat_id 3
        {
            id: 12,
            chat_id: 3,
            user_id: 4,
            content:
                "Je vais parler au fournisseur pour voir s'ils peuvent nous fournir plus de variétés de jus d'orange.",
            mentions: [3],
            created_at: "2024-12-12T12:30:00Z",
        },
        {
            id: 13,
            chat_id: 3,
            user_id: 3,
            content:
                "Super, merci. On aura sûrement des retours positifs sur les nouvelles variétés.",
            mentions: [4],
            created_at: "2024-12-12T12:45:00Z",
        },
        {
            id: 14,
            chat_id: 3,
            user_id: 3,
            content:
                "Il faut aussi prévoir des tests de dégustation avant de lancer la nouvelle gamme.",
            mentions: [4],
            created_at: "2024-12-12T13:00:00Z",
        },

        // Additional messages for chat_id 4
        {
            id: 15,
            chat_id: 4,
            user_id: 5,
            content:
                "Je suis sûr que la dégustation va être un succès. Nous avons des boissons vraiment intéressantes.",
            mentions: [4],
            created_at: "2024-12-12T13:30:00Z",
        },
        {
            id: 16,
            chat_id: 4,
            user_id: 4,
            content:
                "J'espère que ça va plaire. Nous avons un large éventail de nouvelles saveurs.",
            mentions: [5],
            created_at: "2024-12-12T13:45:00Z",
        },

        // Additional messages for chat_id 5
        {
            id: 17,
            chat_id: 5,
            user_id: 1,
            content:
                "Nous devrons nous assurer que les boissons énergétiques arrivent à temps pour la promotion.",
            mentions: [5],
            created_at: "2024-12-12T14:30:00Z",
        },
        {
            id: 18,
            chat_id: 5,
            user_id: 5,
            content:
                "Je vais coordonner avec le fournisseur pour garantir les livraisons.",
            mentions: [1],
            created_at: "2024-12-12T14:45:00Z",
        },
        {
            id: 19,
            chat_id: 5,
            user_id: 2,
            content: "Tout est prêt, nous attendons juste les produits.",
            mentions: [1, 5],
            created_at: "2024-12-12T15:00:00Z",
        },
    ],

    notifications: [
        {
            id: 1,
            user_id: 2,
            message_id: 1,
            read: false,
            created_at: "2024-12-12T10:00:00Z",
        },
        {
            id: 2,
            user_id: 1,
            message_id: 2,
            read: false,
            created_at: "2024-12-12T11:00:00Z",
        },
        {
            id: 3,
            user_id: 3,
            message_id: 3,
            read: false,
            created_at: "2024-12-12T12:00:00Z",
        },
        {
            id: 4,
            user_id: 5,
            message_id: 4,
            read: false,
            created_at: "2024-12-12T13:00:00Z",
        },
        {
            id: 5,
            user_id: 2,
            message_id: 5,
            read: false,
            created_at: "2024-12-12T14:00:00Z",
        },
    ],
};

export default database;
