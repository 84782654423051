import database from "./mockData";

// Simulated Backend Functions
const api = {
    getMessages(chatId) {
        return database.messages.filter((msg) => msg.chat_id === chatId);
    },
    postMessage({ chatId, userId, content }) {
        const mentionRegex = /@(\w+)/g;
        const mentions = [...content.matchAll(mentionRegex)].map(
            (match) => match[1]
        );

        const mentionedUsers = database.users.filter((user) =>
            mentions.includes(user.username)
        );

        const newMessage = {
            id: database.messages.length + 1,
            chat_id: chatId,
            user_id: userId,
            content,
            mentions: mentionedUsers.map((user) => user.id),
            created_at: new Date().toISOString(),
        };

        database.messages.push(newMessage);

        // Create notifications for mentioned users
        mentionedUsers.forEach((user) => {
            database.notifications.push({
                id: database.notifications.length + 1,
                user_id: user.id,
                message_id: newMessage.id,
                read: false,
                created_at: new Date().toISOString(),
            });
        });

        return newMessage;
    },
    getNotifications(userId) {
        return database.notifications.filter(
            (notif) => notif.user_id === userId && !notif.read
        );
    },
    markNotificationAsRead(notificationId) {
        const notification = database.notifications.find(
            (notif) => notif.id === notificationId
        );
        if (notification) notification.read = true;
    },
};

export default api;
