import React from "react";

import "react-icons/fi";
import { FiLogOut, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import build_param from "../../build_params.json";

function Header() {
    const navigate = useNavigate();

    const { logout } = useAuth0();

    const frontendPath = build_param.frontendPath;

    const logout_auth0 = async () => {
        localStorage.removeItem("token");
        localStorage.removeItem("token_test");
        localStorage.removeItem("user");
        localStorage.removeItem("layoutData");
        localStorage.removeItem("language");
        logout({
            logoutParams: {
                returnTo: `${frontendPath}/`,
            },
        });
    };

    return (
        <header
            style={{ flexShrink: 0, width: "100%" }}
            className="text-center text-black z-50 bg-white sticky md:hidden"
        >
            <div className="flex flex-row w-full items-center pt-5 pb-5 mt-10">
                <div
                    className="flex flex-col items-center w-1/4"
                    onClick={() => navigate("/profile")}
                >
                    <div className="w-35 h-35 overflow-hidden rounded-full p-3 bg-gray-100 text-lg">
                        <FiUser />
                    </div>
                </div>
                <div className="flex flex-col items-center w-1/2"></div>
                <div
                    className="flex flex-col items-center w-1/4"
                    onClick={() => logout_auth0()}
                >
                    <div className="w-35 h-35 text-black">
                        <FiLogOut />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
