import React, { useEffect, useState } from "react";
import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Lead = ({ showReminderModal, showArchiveModal, lead, fetchLeads }) => {
    const [locale, setLocale] = useState();

    const convertedButtonStyle =
        "transition text-xs md:text-sm font-bold   bg-gold cursor-pointer hover:bg-black hover:text-gold text-black px-2 py-1 mx-auto rounded-full";

    const buttonStyle =
        "transition text-xs md:text-sm font-bold border border-black  bg-black cursor-pointer hover:bg-white hover:text-black text-white px-2 py-1 mx-auto rounded-full";

    const updateNotifications = async (new_status) => {
        lead.status = new_status;
        await client.updateLead(lead.lead_id, lead);
        await fetchLeads();
    };

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
    }, []);

    return (
        lead && (
            <>
                <IntlProvider
                    locale={locale}
                    messages={messages[locale]}
                    defaultLocale="en"
                >
                    <div className="flex flex-wrap items-end justify-between bg-white rounded-3xl bg-white border border-gray-200 w-full transform  mb-3 p-1 z-50">
                        <div className="flex flex-col w-full pl-5 pt-2">
                            <div className="mb-2 st text-black uppercase title-font font-bold">
                                {lead?.client_name}
                            </div>
                            <div className="mb-2 text-black text-sm font-semibold">
                                Distance:&nbsp;
                                {lead.distance}
                                &nbsp;km
                            </div>
                        </div>
                        <div className="flex flex-row w-full">
                            <div className="w-full flex flex-col xl:w-1/2 md:w-1/2 sm:w-1/2 pl-5">
                                <div className="relative float-left pb-4 ">
                                    <h2 className="text-sm text-black">
                                        {lead?.country}
                                    </h2>
                                    <h2 className=" text-sm  text-black">
                                        {lead?.region}
                                    </h2>
                                    <h2 className="text-sm  text-black">
                                        {lead?.phone}
                                    </h2>
                                    <h2 className=" text-sm  text-black underline">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={lead?.website}
                                        >
                                            <FormattedMessage
                                                id="lead.website"
                                                defaultMessage="Website"
                                            />
                                        </a>
                                    </h2>
                                </div>
                            </div>
                            <div className="flex w-full xl:w-1/2 md:w-1/2 sm:w-1/2 pl-5">
                                <div className="relative  ">
                                    <h2 className="items-center text-sm  text-black">
                                        <FormattedMessage
                                            id="lead.relevance"
                                            defaultMessage="Relevance"
                                        />
                                        : Haute
                                    </h2>
                                    <h2 className="items-center text-sm  text-black">
                                        <FormattedMessage
                                            id="lead.type"
                                            defaultMessage="Type"
                                        />
                                        : €€
                                    </h2>
                                    {/* <h2 className="items-center text-sm text-black">
                                        <FormattedMessage
                                            id="lead.cuisine"
                                            defaultMessage="Cuisine"
                                        />
                                        : {lead?.client_level_4}
                                    </h2> */}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row w-full pb-3 pl-5 mt-5">
                            <div className="flex w-1/4">
                                <a
                                    className={convertedButtonStyle}
                                    onClick={() => {
                                        updateNotifications("converted");
                                    }}
                                >
                                    <FormattedMessage
                                        id="lead.converted"
                                        defaultMessage="Converted"
                                    />
                                </a>
                            </div>
                            <div className="flex w-3/5">
                                <a
                                    className={buttonStyle}
                                    onClick={() => {
                                        showReminderModal();
                                    }}
                                >
                                    <FormattedMessage
                                        id="lead.remind_me_later"
                                        defaultMessage="Remind me later"
                                    />
                                </a>
                            </div>
                            <div className="flex w-1/4">
                                <a
                                    className={buttonStyle}
                                    onClick={() => {
                                        showArchiveModal();
                                    }}
                                >
                                    <FormattedMessage
                                        id="lead.decline"
                                        defaultMessage="Decline"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </IntlProvider>
            </>
        )
    );
};

export default Lead;
